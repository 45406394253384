<template>
<article class="quiz-spring" id="js_pagetop">
	<h1 class="quiz-spring__header" id="js_observer">
		<img src="@/assets/img/quiz_spring/title.png" alt="春の体調管理に役に立つ知識をチェック！ 春のお天気健康検定">
		<p class="quiz-spring__header--logo"><span>presented by</span><img src="~@/assets/img/lp_spring2023/logo.png" alt="頭痛ーる"></p>
	</h1>

	<!-- 紹介 -->
	<p class="quiz-spring__intro">春の体調管理やより良い暮らしに役立つ知識を腕試し！厳選10問のクイズに答えて、健康天気レベルを判定！！</p>
	<!-- /紹介 -->

	<!-- クイズ -->
	<section class="quiz-spring__quiz">
		<ul class="quiz-spring__quiz--list">
			<li class="quiz-spring__quiz--item">
				<span class="quiz-spring__quiz--heading">Q.1</span>
				<p class="quiz-spring__quiz--question">春の不調の原因になる「春の５K」とは、乾燥、花粉、強風、黄砂、もう一つは？</p>
				<div class="quiz-spring__quiz--answer">
					<p @click="onClickQuiz(0, 1)" :class="{ 'is-check': answers[0] === 1 }"><em>A</em><span>高気圧</span></p>
					<p @click="onClickQuiz(0, 2)" :class="{ 'is-check': answers[0] === 2 }"><em>B</em><span>寒暖差</span></p>
					<p @click="onClickQuiz(0, 3)" :class="{ 'is-check': answers[0] === 3 }"><em>C</em><span>寒冷前線</span></p>
				</div>
			</li>
			<li class="quiz-spring__quiz--item">
				<span class="quiz-spring__quiz--heading">Q.2</span>
				<p class="quiz-spring__quiz--question">花粉の飛ぶ量が増えるのは一日の中でいつ頃？正しい組み合わせは？</p>
				<div class="quiz-spring__quiz--answer">
					<p @click="onClickQuiz(1, 1)" :class="{ 'is-check': answers[1] === 1 }"><em>A</em><span>朝と昼前後</span></p>
					<p @click="onClickQuiz(1, 2)" :class="{ 'is-check': answers[1] === 2 }"><em>B</em><span>朝と夕方</span></p>
					<p @click="onClickQuiz(1, 3)" :class="{ 'is-check': answers[1] === 3 }"><em>C</em><span>昼前後と夕方</span></p>
				</div>
			</li>
			<li class="quiz-spring__quiz--item">
				<span class="quiz-spring__quiz--heading">Q.3</span>
				<p class="quiz-spring__quiz--question">きちんととめてないと洗濯物が飛ばされてしまう恐れがある風速はどれくらい？</p>
				<div class="quiz-spring__quiz--answer">
					<p @click="onClickQuiz(2, 1)" :class="{ 'is-check': answers[2] === 1 }"><em>A</em><span>5メートル以上</span></p>
					<p @click="onClickQuiz(2, 2)" :class="{ 'is-check': answers[2] === 2 }"><em>B</em><span>10メートル以上</span></p>
					<p @click="onClickQuiz(2, 3)" :class="{ 'is-check': answers[2] === 3 }"><em>C</em><span>15メートル以上</span></p>
				</div>
			</li>
			<li class="quiz-spring__quiz--item">
				<span class="quiz-spring__quiz--heading">Q.4</span>
				<p class="quiz-spring__quiz--question">サクラが散り始めるサインは次のうち、どれ？</p>
				<div class="quiz-spring__quiz--answer">
					<p @click="onClickQuiz(3, 1)" :class="{ 'is-check': answers[3] === 1 }"><em>A</em><span>花の中心が赤くなる</span></p>
					<p @click="onClickQuiz(3, 2)" :class="{ 'is-check': answers[3] === 2 }"><em>B</em><span>花びらに縞模様が入る</span></p>
					<p @click="onClickQuiz(3, 3)" :class="{ 'is-check': answers[3] === 3 }"><em>C</em><span>葉っぱが色付く</span></p>
				</div>
			</li>
			<li class="quiz-spring__quiz--item">
				<span class="quiz-spring__quiz--heading">Q.5</span>
				<p class="quiz-spring__quiz--question">春の空が霞んで見える原因の一つ「黄砂」は、どこから飛んで来る？</p>
				<div class="quiz-spring__quiz--answer">
					<p @click="onClickQuiz(4, 1)" :class="{ 'is-check': answers[4] === 1 }"><em>A</em><span>サハラ砂漠</span></p>
					<p @click="onClickQuiz(4, 2)" :class="{ 'is-check': answers[4] === 2 }"><em>B</em><span>アラビア砂漠</span></p>
					<p @click="onClickQuiz(4, 3)" :class="{ 'is-check': answers[4] === 3 }"><em>C</em><span>ゴビ砂漠</span></p>
				</div>
			</li>
			<li class="quiz-spring__quiz--item">
				<span class="quiz-spring__quiz--heading">Q.6</span>
				<p class="quiz-spring__quiz--question">健康を保つのに最適な湿度は何％くらい？</p>
				<div class="quiz-spring__quiz--answer">
					<p @click="onClickQuiz(5, 1)" :class="{ 'is-check': answers[5] === 1 }"><em>A</em><span>35～45％</span></p>
					<p @click="onClickQuiz(5, 2)" :class="{ 'is-check': answers[5] === 2 }"><em>B</em><span>45～55％</span></p>
					<p @click="onClickQuiz(5, 3)" :class="{ 'is-check': answers[5] === 3 }"><em>C</em><span>55～65％</span></p>
				</div>
			</li>
			<li class="quiz-spring__quiz--item">
				<span class="quiz-spring__quiz--heading">Q.7</span>
				<p class="quiz-spring__quiz--question">紫外線の強さは快晴の日を100％とした時に、くもりの日は何％の強さになる？</p>
				<div class="quiz-spring__quiz--answer">
					<p @click="onClickQuiz(6, 1)" :class="{ 'is-check': answers[6] === 1 }"><em>A</em><span>90％</span></p>
					<p @click="onClickQuiz(6, 2)" :class="{ 'is-check': answers[6] === 2 }"><em>B</em><span>60％</span></p>
					<p @click="onClickQuiz(6, 3)" :class="{ 'is-check': answers[6] === 3 }"><em>C</em><span>30％</span></p>
				</div>
			</li>
			<li class="quiz-spring__quiz--item">
				<span class="quiz-spring__quiz--heading">Q.8</span>
				<p class="quiz-spring__quiz--question">急激な気温の低下をもたらすのは、次のうちどれが通過した時？</p>
				<div class="quiz-spring__quiz--answer">
					<p @click="onClickQuiz(7, 1)" :class="{ 'is-check': answers[7] === 1 }"><em>A</em><span>閉塞前線</span></p>
					<p @click="onClickQuiz(7, 2)" :class="{ 'is-check': answers[7] === 2 }"><em>B</em><span>温暖前線</span></p>
					<p @click="onClickQuiz(7, 3)" :class="{ 'is-check': answers[7] === 3 }"><em>C</em><span>寒冷前線</span></p>
				</div>
			</li>
			<li class="quiz-spring__quiz--item">
				<span class="quiz-spring__quiz--heading">Q.9</span>
				<p class="quiz-spring__quiz--question">光を浴びることで脳から分泌される通称「幸せのホルモン」は次のうちどれ？</p>
				<div class="quiz-spring__quiz--answer">
					<p @click="onClickQuiz(8, 1)" :class="{ 'is-check': answers[8] === 1 }"><em>A</em><span>アドレナリン</span></p>
					<p @click="onClickQuiz(8, 2)" :class="{ 'is-check': answers[8] === 2 }"><em>B</em><span>セロトニン</span></p>
					<p @click="onClickQuiz(8, 3)" :class="{ 'is-check': answers[8] === 3 }"><em>C</em><span>ドーパミン</span></p>
				</div>
			</li>
			<li class="quiz-spring__quiz--item">
				<span class="quiz-spring__quiz--heading">Q.10</span>
				<p class="quiz-spring__quiz--question">山を吹き降りる風の影響で気温が高くなることがあります。この現象を何という？</p>
				<div class="quiz-spring__quiz--answer">
					<p @click="onClickQuiz(9, 1)" :class="{ 'is-check': answers[9] === 1 }"><em>A</em><span>ブロッケン現象</span></p>
					<p @click="onClickQuiz(9, 2)" :class="{ 'is-check': answers[9] === 2 }"><em>B</em><span>ラニーニャ現象</span></p>
					<p @click="onClickQuiz(9, 3)" :class="{ 'is-check': answers[9] === 3 }"><em>C</em><span>フェーン現象</span></p>
				</div>
			</li>
		</ul>
		<!-- 結果を見るボタン -->
		<a href="javascript:void(0);" @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=quizSpringRes`, _result: computedCorrectNumber } })"
			:class="[
				'quiz-spring__quiz--result',
				'gtm-link_click_count01',
				{ 'is-active': !answers.includes(0) }
			]"
			data-gtm-event-cat-label="春のお天気健康検定_結果を見るボタン"
			data-gtm-event-act-label="trackingQuizSpringResultBtn"
		>結果をみる</a>
		<!-- /結果を見るボタン -->
	</section>
	<!-- /クイズ -->

	<!-- 頭痛ーるTOPへ戻るボタン -->
	<nav class="quiz-spring__outro">
		<!-- お天気健康クイズTOPへボタン -->
		<a href="javascript:void(0);" @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=lp`, _lpType: 'lpspring2023' } })" class="quiz-spring__outro--quiztop">お天気健康クイズ<br>TOPへ</a>
		<!-- /お天気健康クイズTOPへボタン -->

		<!-- 頭痛ーるTOPへボタン -->
		<a href="javascript:void(0);" @click.prevent="$router.push({ name: 'Cpsite', query: { id: sugotokuContentsId } })" class="quiz-spring__outro--sitetop">頭痛ーるTOPへ</a>
		<!-- /頭痛ーるTOPへボタン -->
	</nav>
	<!-- /頭痛ーるTOPへ戻るボタン -->

	<!-- 上部に戻るボタン -->
	<nav class="quiz-spring__pagetop" id="js_pagetop_btn">
		<a href="javascript:void(0);" @click.prevent="onScroll('js_pagetop')" class="quiz-spring__pagetop--link">
			<img src="~@/assets/img/pagetop_btn01.png" alt="ページTOPにもどる">
		</a>
	</nav>
	<!-- /上部に戻るボタン -->
</article>
</template>

<script>
import cmnConst from '@/assets/js/constant.js'

export default {
	name: 'QuizSpring',
	components: {
	},
	data () {
		return {
			sugotokuContentsId: cmnConst.SUGOTOKU_CONTENTS_ID,
			backendUrl: cmnConst.BACKEND_URL,
			$html: null,
			answers: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],	// 回答を格納する配列
			pageTopObserver: null
		}
	},
	props: {
	},
	computed: {
		// 何問正解したかを計算して％のNumberを返す
		computedCorrectNumber () {
			let correct = 0
			const correctAnswers = [2, 3, 1, 1, 3, 2, 2, 3, 2, 3]
			const questionsNumber = correctAnswers.length

			for (let i = 0; i < questionsNumber; i++) {
				if (correctAnswers[i] === this.answers[i]) correct++
			}
			return String(correct / questionsNumber * 100)
		}
	},
	mounted () {
		// 横幅に合わせてフォントサイズや余白をレスポンシブに可変する対応
		const propWidth = document.body.offsetWidth
		const style = propWidth <= 640 ? 'font-size: 16px; font-size: 4.267vw;' : 'font-size: 27px;'
		this.$html = document.getElementsByTagName('html')[0]
		this.$html.setAttribute('style', style)

		// ページTOPに戻るボタン表示の処理
		const $target = document.getElementById('js_observer')
		const $pagetopBtn = document.getElementById('js_pagetop_btn')
		this.pageTopObserver = new IntersectionObserver(entries => {
			const entry = entries[0]
			if (entry.isIntersecting) {
				$pagetopBtn.classList.remove('is-show')
			} else {
				$pagetopBtn.classList.add('is-show')
			}
		})
		this.pageTopObserver.observe($target)
	},
	beforeDestroy () {
		this.$html.removeAttribute('style')
		this.pageTopObserver.disconnect()
	},
	methods: {
		// クイズのチェック状況を更新するメソッド
		onClickQuiz (index, value) {
			this.$set(this.answers, index, value)
		},

		// ページスクロール用の関数
		onScroll (id) {
			const elem = document.getElementById(id)
			const options = {
				container: 'body',
				duration: 500,
				easing: 'ease-out',
				offset: 0,
				cancelable: false,
				onStart: false,
				onDone: false,
				onCancel: false,
				x: false,
				y: true
			}
			this.$scrollTo(elem, options)
		}
	}
}
</script>

<style lang="scss" scoped>
@import "../assets/sass/variable";

$quiz-primary-color: #43C2A0;
$quiz-secondary-color: #FF5A7C;
$quiz-background-color: #FFF8E8;
$quiz-active-color: #FFE5E8;
$quiz-submit-disabled-border: #A7A7A7;
$quiz-submit-border: #F34267;

$spacing-3: 0.187rem;
$spacing-12: 0.75rem;
$spacing-13: 0.812rem;
$spacing-23: 1.437rem;
$spacing-27: 1.687rem;
$spacing-54_5: 3.406rem;

$font-size-36: 2.25rem;

$line-height-1_2: 1.2;
$line-height-1_375: 1.375;

.quiz-spring {
	font-family: $lp-family-primary;
	max-width: 640px;
	margin: 0 auto;
	padding: 0 $spacing-14;
	background-color: $quiz-background-color;
	color: $text-primary;
	font-size: $font-size-16;
	line-height: $line-height-1_375;

	ul, li { list-style: none; }

	em { font-style: normal; }

	img {
		width: 100%;
		display: block;
	}

	/* ヘッダー */
	&__header {
		margin: $spacing-10 auto 0;
		box-sizing: border-box;

		&--logo {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			margin: $spacing-2 auto 0;
			font-size: $font-size-10;

			& > img {
				width: 25.9366%;
				margin-left: $spacing-4;
			}
		}
	}

	&__intro { margin: $spacing-10 auto 0; }

	&__quiz {
		margin: $spacing-20 auto 0;

		&--list {
			padding: 0;
			margin: 0;
		}

		&--item {
			margin: $spacing-20 auto 0;
			padding: $spacing-20 $spacing-20 $spacing-30;
			background-color: $background-primary;
			border: 1px solid $quiz-primary-color;
			border-radius: $spacing-10;

			&:first-of-type { margin: 0 auto; }
		}

		&--heading {
			display: block;
			padding: 0 0 $spacing-6;
			font-family: $lp-family-tertiary;
			font-size: $font-size-36;
			font-weight: normal;
			color: $quiz-primary-color;
			line-height: $line-height;
			text-align: center;
			border-bottom: 1px dashed $quiz-primary-color;
		}

		&--question {
			margin: $spacing-14 auto 0;
			font-family: $lp-family-secondary;
			font-size: $font-size-18;
		}

		&--answer {
			margin: $spacing-27 auto 0;

			& > p {
				display: flex;
				align-items: center;
				width: 91.8033%;
				padding: $spacing-4 $spacing-12;
				margin: $spacing-13 auto 0;
				background-color: $background-primary;
				border: 2px solid $quiz-secondary-color;
				border-radius: $spacing-23;
				box-sizing: border-box;

				&:first-of-type { margin: 0 auto; }

				& > em,
				& > span { display: block }

				& > em {
					display: block;
					width: 11.1112%;
					font-family: $lp-family-tertiary;
					font-size: $font-size-24;
					text-align: center;
					color: $quiz-secondary-color;
				}

				& > span {
					flex: 1;
					margin: 0 0 0 $spacing-8;
					padding: $spacing-2 0 $spacing-2 $spacing-20;
					font-family: $lp-family-secondary;
					border-left: 1px solid $quiz-secondary-color;
				}

				&.is-check { background-color: $quiz-active-color; }
			}
		}

		&--result {
			display: block;
			width: 86.4554%;
			margin: $spacing-40 auto 0;
			padding: $spacing-10 0;
			box-sizing: border-box;
			background: $button-secondary;
			border-radius: $spacing-6;
			font-family: $lp-family-secondary;
			text-align: center;
			text-decoration: none;
			color: $text-seconday;
			font-size: $font-size-24;
			box-shadow: 0 $spacing-3 $quiz-submit-disabled-border;
			pointer-events: none;

			&.is-active {
				background: $quiz-secondary-color;
				box-shadow: 0 $spacing-3 $quiz-submit-border;
				pointer-events: auto;
			}
		}
	}

	/* アウトロ */
	&__outro {
		position: relative;
		margin: $spacing-30 auto 0;
		padding: 0;

		&--quiztop {
			display: block;
			width: 86.4554%;
			margin: 0 auto;
			padding: $spacing-10 0;
			font-family: $lp-family-secondary;
			text-align: center;
			text-decoration: none;
			color: $text-seconday;
			font-size: $font-size-20;
			line-height: $line-height-1_2;
			background-color: $quiz-secondary-color;
			border-radius: $spacing-6;
			box-shadow: 0 $spacing-3 $quiz-submit-border;
		}

		&--sitetop {
			font-family: $lp-family-secondary;
			display: block;
			width: 66.2825%;
			margin: $spacing-30 auto 0;
			padding: $spacing-10 0;
			border-radius: $spacing-26;
			font-weight: normal;
			text-align: center;
			text-decoration: none;
			box-sizing: border-box;
			color: $text-seconday;
			font-size: $font-size-24;
			background-color: $primary;
		}
	}

	/* ページTOPに戻るボタン */
	&__pagetop {
		position: sticky;
		max-width: 640px;
		width: 100%;
		margin: -$spacing-54_5 auto 0;
		bottom: 0;
		opacity: 0;
		transition: all .3s ease-in;
		pointer-events: none;

		&.is-show { opacity: 1; }

		&--link {
			display: block;
			width: 14.1211%;
			margin-left: auto;
			pointer-events: auto;

			& > img { display: block; }
		}
	}
}
</style>
